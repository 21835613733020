<script>
/* eslint-disable */
import _ from "lodash";

export default {
  name: "google-location",
  props: {
    value: {
      type: String,
      default: ""
    },
    refsAddress: {
      type: String,
      default: "address"
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      ac: null,
      address: "",
      unit: "",
      value2: "",
      unitDelimeter: ""
    };
  },
  methods: {
    getAddress: function(place) {
      let address = new Array();
      let componentForm = {
        street_number: "short_name",
        route: "long_name",
        locality: "long_name",
        administrative_area_level_1: "short_name",
        country: "short_name",
        postal_code: "short_name"
      };
      if (place && place.address_components) {
        for (var i = 0; i < place.address_components.length; i++) {
          var addressType = place.address_components[i].types[0];

          if (componentForm[addressType]) {
            var val = place.address_components[i][componentForm[addressType]];
            address[addressType] = val;
          }
        }
      }
      return address;
    },

    onChange: function() {
      this.$emit("onChange");
    }
  },
  computed: {
    newValue: {
      get: function() {
        return this.value2;
      },
      set: function(v) {
        var arr = _.split(v, "/");
        if (arr.length > 1) {
          this.unit = arr[0];
          this.value2 = arr[1];
          this.unitDelimeter = "/";
        } else {
          var arr2 = _.split(v, "-");
          if (arr2.length > 1) {
            this.unitDelimeter = "-";
            this.unit = arr2[0];
            this.value2 = arr2[1];
          } else {
            this.value2 = v;
          }
        }
        //   this.value = this.value2;
      }
    }
  },
  mounted: function() {
    this.value2 = this.value;
    let me = this;
    this.ac = new google.maps.places.Autocomplete(this.$refs[me.refsAddress], {
      // types: ['geocode']
    });
    this.ac.setComponentRestrictions({ country: ["au", "nz"] });

    this.ac.addListener("place_changed", function() {
      let addr = me.getAddress(me.ac.getPlace());
      me.$emit("onPlaceChange", {
        address: me.unit + me.unitDelimeter + me.value2,
        levelUnitLot: me.unit,
        street_name: [addr.street_number, addr.route].join(" ").trim(),
        suburb: addr.locality,
        state: addr.administrative_area_level_1,
        postcode: addr.postal_code,
        country: addr.country
      });
      me.unit = "";
      me.unitDelimeter = "";
      me.$emit("input", me.$refs[me.refsAddress].value);
    });
    me.$refs[this.refsAddress].addEventListener("input", function(e) {
      me.$emit("input", me.$refs[me.refsAddress].value);
    });
  }
};
</script>

<template>
  <div>
    <i class="bx bx-map"></i>
    <input
      v-model="newValue"
      :ref="refsAddress"
      type="text"
      class="form-control"
      @change="onChange"
      :disabled="isDisabled"
    />
  </div>
</template>

<style>
.pac-container {
  z-index: 9999;
}

/* .pac-logo:after {
        content: none !important;
    } */
</style>

<style scoped>
.V3 i.bx {
  font-size: 16px;
  width: 26px;
  text-align: center;
  margin-right: -26px;
  position: relative;
  z-index: 1;
  float: left;
  padding-top: 7px;
  opacity: 0.5;
}

.V3 i.bx + input {
  padding-left: 26px;
}
</style>
