<template>
    <div class="row settings-page">
        <div class="col-md-5">
            <div class="card">
                <div class="card-header bg-navyblue">Claims - Standard</div>
                <div class="card-block bg-white">
                    <div class="form-group row no-gutter">
                        <label
                                class="col-lg-5 col-form-label">Claim Prefix</label>
                        <div class="col-lg-7">
                            <input v-model="claims.prefix"
                                   type="text"
                                   class="form-control"
                                   placeholder="Claim Prefix">
                        </div>
                    </div>
                    <div class="form-group row no-gutter">
                        <label
                                class="col-lg-5 col-form-label">Claim Number Start</label>
                        <div class="col-lg-7">
                            <input v-model="claims.startNumber"
                                   type="text"
                                   class="form-control"
                                   placeholder="Claim Number Start">
                        </div>
                    </div>
                    <div class="form-group row no-gutter">
                        <label
                                class="col-lg-5 col-form-label">Claim Number
                            Length</label>
                        <div class="col-lg-7">
                            <multiselect
                                    v-model="claims.lengthNumber"
                                    :options="[1,2,3,4,5,6,7,8,9,10]"
                                    :showLabels="false"
                                    :option-height="29"
                                    :max-height="203"
                                    :close-on-select="true"
                                    :allow-empty="false"
                                    placeholder="Claim Number Length"
                            />
                        </div>
                    </div>
                    <div class="form-group row no-gutter">
                        <label class="col-lg-5 col-form-label">Claim Heading Default</label>
                        <div class="col-lg-7">
                            <multiselect
                                    v-model="computedHeadingDefault"
                                    :options="headingDefaultList"
                                    track-by="key"
                                    label="value"
                                    :showLabels="false"
                                    :option-height="29"
                                    :max-height="203"
                                    :close-on-select="true"
                                    :allow-empty="false"
                                    placeholder="Select Default"
                            />
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="col-md-7">
            <div class="card">
                <div class="card-header bg-navyblue">Claims - Statuses</div>

                <div class="card-block bg-white p-0">
                    <div>
                        <table class="table tab-special mb-0" style="table-layout:fixed;">
                            <colgroup>
                                <col span="1" class="twidth-order"/>
                                <col span="1" class="twidth-name"/>
                                <col span="1" class="twidth-action"/>
                            </colgroup>
                            <thead class="thead-light">
                            <tr>
                                <th class="tfont-13-bold verticalTextTh twidth-order">Order</th>
                                <th class="tfont-13-bold verticalTextTh twidth-name">Status Name</th>
                                <th class="tfont-13-bold verticalTextTh twidth-action"></th>
                            </tr>
                            </thead>
                            <draggable v-model="statuses"
                                       :move="updateOrder"
                                       class="dragArea"
                                       :element="'tbody'"
                                       draggable=".draggeble"
                                       ref="drgbl">
                                <!--                                eslint-disable-->
                                <template v-for="(status, index) in statuses">
                                    <tr v-if="status.to_delete !== true" :class="{'is-edit': status.dynamic_name != '0', 'active': true, 'draggeble': status.dynamic_order != '0' }">
                                        <td class="tfont-12-reguler orderNameClass twidth-order">
                                            {{ index + 1 }}
                                        </td>
                                        <td class="tfont-12-bold" style="font-size: 12px;     font-weight: 600;    line-height: 16px;">
                                            <template v-if="status.dynamic_name != '0'">
                                                <input type="text"
                                                       class="form-control"
                                                       v-model="status.name"
                                                       v-on:keyup="empty"
                                                       v-on:keydown="empty"
                                                />
                                            </template>
                                            <template v-else>
                                                {{ status.name }}
                                            </template>
                                        </td>
                                        <td class="claim-status-action tfont-12-reguler twidth-action">
                                            <div class="actions">
                                                <span v-if="status.dynamic_name != '0'"
                                                      class="clickable"
                                                      @click="toDelete(status)">
                                                    <i class='bx bx-trash'></i>
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-else>
                                        <td class="tfont-12-reguler orderNameClass twidth-order" style="opacity: 0.5;">
                                            {{ index + 1 }}
                                        </td>
                                        <td class="tfont-12-bold" style="font-size: 12px;     font-weight: 600;    line-height: 16px; opacity: 0.5;">
                                            {{ status.name }}
                                        </td>
                                        <td class="claim-status-action tfont-12-reguler twidth-action">

                                        </td>
                                    </tr>
                                </template>
                            </draggable>

                        </table>

                        <div class="statuses-btn-group">
                            <input @keyup.enter="handleAddStatus"
                                   v-model="nameAddStatus"
                                   type="text"
                                   title="new status"
                                   placeholder="Add new status name"
                                   class="form-control add-status-input-settings">
                            <button type="button"
                                    @click="handleAddStatus"
                                    @keyup.space="empty"
                                    style="width: 120px;"
                                    class="btn btn-primary add-status-button">
                                Add Status
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    /* eslint-disable */
    import Multiselect from 'vue-multiselect';
    import {mapGetters} from "vuex";
    import Axios from "axios";
    import Draggable from 'vuedraggable';

    export default {
        name: "settings-claims",
        components: {
            multiselect: Multiselect,
            Draggable,
        },

        watch: {},
        data() {
            return {
                claims: {
                    prefix: '',
                    lengthNumber: 6,
                    startNumber: 1,
                    headingDefault: null,
                },
                statuses: [],
                nameAddStatus: null,
                headingDefaultList: [
                    {key: 'insured', value: 'Insured - Vehicle Owner'},
                    {key: 'otherParty', value: 'Other Party - Vehicle Owner'},
                ],
            };
        },
        mounted() {
            this.loadSettings();
        },
        computed: {
            ...mapGetters({}),
            computedHeadingDefault: {
                get() {
                    let result = null;
                    if (this.claims.headingDefault) {
                        this.headingDefaultList.forEach(s => {
                            if (this.claims.headingDefault == s.key) {
                                result = s;
                            }
                        });
                    }
                    return result;
                },
                set(val) {
                    this.claims.headingDefault = val.key;
                }
            },
        },
        watch: {
            statuses: {
                deep: true,
                handler() {
                    this.updateOrder();
                }
            },
        },
        methods: {
            empty() {
            },
            loadSettings() {
                Axios.get('/settings/company')
                    .then(response => {
                        if (response.data && response.data._status) {
                            this.claims = response.data.data.claim;
                            this.statuses = response.data.statuses;
                        }
                    })
                    .catch(e => console.error(e));
            },
            clickSave() {
                Axios.post('/settings/claims', {
                    claims: this.claims,
                    statuses: this.statuses,
                })
                    .then(response => {
                        if (response.data && response.data._status) {
                            this.$toast.success('Updated');
                        }
                    })
                    .catch(e => console.error(e));
            },
            handleAddStatus() {
                if (this.nameAddStatus) {

                    this.statuses.push({
                        to_create: true,
                        id: 0,
                        name: this.nameAddStatus,
                        dynamic_name: "1",
                        dynamic_order: "1",
                        order_number: 999,
                    });

                    this.nameAddStatus = '';

                }
            },
            updateOrder() {
                setTimeout(() => {
                    this.statuses.forEach((st, i) => {
                        st.order_number = i;
                    });
                }, 150);
            },
            toDelete(st) {
                this.$set(st, 'to_delete', true);
            },
            rowClass(item/*, type*/) {
                let result = '';

                if (item && item.id === this.selectedUser.id) {
                    result = 'selected-td';
                }
                return result;
            },
        }
    };
</script>

<style scoped>
    .bx-trash {
        color: red;
        font-size: 20px;
    }

    .statuses-btn-group {
        display: flex;
        margin-top: 35px;
    }

    .add-status-input-settings {
        vertical-align: middle;
        height: 38px;
    }

    .add-status-button {
        width: 120px;
        flex: 0 1 120px;
        height: 38px;
        font-size: 13px;
        font-weight: bold;
        color: white;
        border: 1px solid #00B050;
        border-radius: 3px;
        margin-right: 10px;
    }

    .table .twidth-order {
        width: 70px;
        min-width: 70px;
    }

    .table .twidth-name {
        width: auto;
        min-width: 150px;
    }

    .table .twidth-action {
        min-width: 55px;
        width: 55px;

    }

</style>
