<template>
    <b-table responsive hover :items="usersAll" :fields="contactFields">
        <template v-slot:cell(username)="data">
            {{ data.item.firstName }} {{ data.item.lastName }}
        </template>

        <template v-slot:cell(businessLocation)="data">
          <template v-if="data.item.businessLocation">
            {{ getLocation(data.item.businessLocation).name }}
          </template>
        </template>
        <template v-slot:cell(action)="data">
            <div v-if="!data.item.isVisible"
                 class="eye hide-icon"
                 id="hide-icon-tooltip"
                 v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Show users as a contact to suppliers', placement:'leftbottom'}"
                 @click="onClickShow(data.index)">
                <i class="bx bx-hide"></i>
            </div>
            <div v-else
                 class="eye show-icon"
                 id="show-icon-tooltip"
                 v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Hide users as a contact to suppliers', placement:'leftbottom'}"
                 @click="onClickHide(data.index)">
                <i class="bx bx-show"></i>
            </div>
        </template>
    </b-table>
</template>

<script>
    /*eslint-disable */
    import {mapGetters} from 'vuex';
    import Axios from "axios";
    import _ from "lodash";
    export default {
        name: "settings-business-profile-contacts",
        props: {},
        components: {
        },
      data() {
            return {
                contacts: [],
                contactsVisibility: {
                    visible: [],
                    inVisible: []
                },
                contactFields: [
                    {
                        label: "Name",
                        key: "username",
                        sortable: true,
                        tdClass: "table-body font-weight-bold",
                        thClass: "table-header"
                    },
                    {
                        label: "Position",
                        key: "role",
                        sortable: true,
                        tdClass: "table-body",
                        thClass: "table-header"
                    },
                    {
                        label: "Office Phone",
                        key: "workPhone",
                        sortable: true,
                        tdClass: "table-body",
                        thClass: ""
                    },
                    {
                        label: "Mobile Phone",
                        key: "mobilePhone",
                        sortable: true,
                        tdClass: "table-body",
                        thClass: "table-header"
                    },
                    {
                        label: "Email",
                        key: "email",
                        sortable: true,
                        tdClass: "table-body",
                        thClass: "table-header"
                    },
                    {
                        label: "Location",
                        key: "businessLocation",
                        sortable: true,
                        tdClass: "table-body",
                        thClass: "table-header"
                    },
                    {
                        label: "Action",
                        key: "action",
                        sortable: true,
                        tdClass: "table-body text-center",
                        thClass: "table-header text-center"
                    }
                ],
                usersAll: [],
                locations: [],
            }
        },
      mounted() {
        this.loadLocations();
        this.loadUsers();
      },
        methods:{
          loadLocations(){
            Axios.get('/ir/company/locations')
                .then(res => {
                  if(res && res.data && res.data._status){
                    this.locations = res.data.locations;
                  }
                })
                .catch(e => this.$toast.error(e))
          },
          getLocation(id){
            console.log('ID', id)
            return _.find(this.locations, (item) => {
              return id === item.id;
            });
          },
          loadUsers: function (pageNumber = 1, usersLoaded = (/*users*/) => {}) {
            NProgress.start();
            Axios.get('/ir/users', {
              params: {
                pageNum: pageNumber,
                sort_by: 'user_id',
                sort_type: 'DESC'
              }
            }).then(response => {
              //console.log('Users loaded',response.data.users);
              let users = _.map(response.data.users, item => {
                item.id = Number(item.id);
                return item;
              });
              users = _.sortBy(users, ['id']);
              //console.log('Users mapped',users);
              this.usersAll = users;
              //console.log('LOADING USERS', this.usersAll);
              usersLoaded(users);
              NProgress.done();
            }).catch(error => {
              console.log(error);
              NProgress.done();
            });
          },
            onClickShow: function (index) {
                const id = this.usersAll[index].id;
                this.setUserVisibility(id, true)
            },
            onClickHide: function (index) {
              const id = this.usersAll[index].id;
              this.setUserVisibility(id, false)
            },
          setUserVisibility: function (id, visibility = false) {

            NProgress.start();
            Axios({
              method: 'post',
              responseType: 'json',
              url: `/ir/user/${id}`,
              validateStatus: function (status) {
                return status < 500;
              },
              data: {
                id: id,
                isVisible: visibility,
              }
            }).then(response => {
              if (response.status === 200) {
                this.loadUsers(1);
              } else {
                this.$toast.error(response.data.msg);
              }
              NProgress.done();
            }).catch(error => {
              console.error(error);
              NProgress.done();
            });
          },
        }
    }

    </script>
