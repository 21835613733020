<template>

    <div class="row business-details">
        <div class="col-md-6">
            <div class="card mt-3">
                <div class="card-header">Details</div>
                <div class="card-block mt-3">
                    <div class="form-group row">

                        <label class="col col-form-label col-lg-4">Business Mailbox Name</label>
                        <div class="col-lg-8">
                            <div class="postfix-input-holder">
                                <input type="text" v-model="companyInfo.mailboxName"
                                       class="form-control" @blur="testMailboxNameUnique" placeholder/>
                                <span class="text-danger" v-show="!validateEmail">Please enter valid email name</span>
                                <span class="text-danger" v-show="!mailboxUnique">This mailbox is already taken</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card mt-3">
                <div class="card-header">Claims</div>
                <div class="card-block mt-3">

                    <div class="form-group row">

                        <label class="col col-form-label col-lg-4">Email Subject Line and Body Matching</label>
                        <div class="col-lg-8">
                            <multiselect
                                    v-model="matchingSetting"
                                    tag-placeholder="Add this as new tag"
                                    placeholder="Search or add a tag"
                                    label="name"
                                    track-by="code"
                                    :options="matchingOptions"
                                    :multiple="true"
                                    :taggable="true"
                            ></multiselect>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import Multiselect from 'vue-multiselect';
    import {mapGetters} from "vuex";
    import Axios from "axios";

    export default {
        name: "BusinessMailbox",
        components: {
            Multiselect,
        },
        data() {
            return {
                matchingOptions: [
                    {name: 'Assmt Nbr', code: 'assessment_number'},
                    {name: 'ClaimLinq Claim Nbr', code: 'claimlinq_claim_number'},
                    {name: 'Rental Nbr', code: 'rental_number'},
                    {name: 'Insurer Claim Nbr', code: 'insurer_claim_number'},
                    {name: 'Legal Ref', code: 'legal_ref'},
                    {name: 'Repairer Quote Nbr', code: 'repairer_quote_number'},
                    {name: 'Repairer Supp Nbr', code: 'repairer_supp_number'},
                ],
                // matchingSetting: [],
                companyInfo: {},
                mailboxUnique: true,
            };
        },
        computed: {
            ...mapGetters({
                originalCompanyInfo: "currentCompany/getDetails",
                addressStates: 'addressStates',
                addressCountries: 'addressCountries',
            }),
            matchingSetting: {
                get() {
                    if (!this.companyInfo || !this.companyInfo.incomingEmailMatching) {
                        return [];
                    }
                    let res = [];
                    this.matchingOptions.forEach(el => {
                        if (this.companyInfo.incomingEmailMatching[el.code]) {
                            res.push(Object.assign({}, el));
                        }
                    });
                    return res;
                },
                set(newVal) {
                    this.matchingOptions.forEach(el => {
                        this.companyInfo.incomingEmailMatching[el.code] = false;
                    });
                    newVal.forEach(el => {
                        this.companyInfo.incomingEmailMatching[el.code] = true;
                    });
                },
            },
            validateEmail() {
                if (!this.companyInfo || !this.companyInfo.mailboxName) {
                    return true;
                }
                const exp = /^[a-zA-Z0-9-_.]*$/i;
                return exp.test(this.companyInfo.mailboxName);
            },
        },
        watch: {
            originalCompanyInfo: {
                deep: true,
                handler(newVal) {
                    this.companyInfo = Object.assign({}, newVal);
                },
            }
        },
        methods: {
            testMailboxNameUnique() {
                if (this.companyInfo.mailboxName == '' || !this.validateEmail) {
                    this.mailboxUnique = true;
                    return;
                }
                Axios.post(`/company/check-mailbox-unique`, {mailboxName: this.companyInfo.mailboxName})
                    .then(response => {
                        if (response.data && response.data._status) {
                            this.mailboxUnique = true;
                        } else {
                            this.mailboxUnique = false;
                        }
                    })
                    .catch(e => {
                        this.mailboxUnique = false;
                        console.error(e);
                    });
            },
            clickSave() {
                NProgress.start();
                Axios.post('/ir/company/update', {details: this.companyInfo})
                // eslint-disable-next-line no-unused-vars
                    .then(response => {
                        this.$store.dispatch('currentCompany/loadCompanyInfo', {}, {root: true});
                    })
                    .catch(error => {
                        console.log(error);
                    })
                    .finally(() => {
                        NProgress.done();
                    });
            },
        },
        mounted() {
            this.companyInfo = Object.assign({}, this.originalCompanyInfo);
        },
    };
</script>

<style scoped>

    .postfix-input-holder {
        position: relative;
    }

    .postfix-input-holder input {
        text-align: right;
        padding-right: 112px;
    }

    .postfix-input-holder::after {
        content: '@claimlinq.com.au';
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        box-sizing: border-box;
        padding: 0.375rem 0.75rem;
        padding-left: 0;
        font-size: 12px;
        font-weight: 600;
        line-height: 20px;
        border-width: 1px;
        border-style: solid;
        border-color: rgba(0, 0, 0, 0);
        opacity: 0.7;
    }

</style>
